import React, { Component } from 'react';
import { Typography } from '@robotsnacks/ui';
import { BlockComponentProps } from '../BlockComponent';
import { TitleBlockAttributes } from './TitleBlockAttributes';
import TitleBlockWrapper from './TitleBlockWrapper';

export interface ReadOnlyTitleBlockProps
  extends BlockComponentProps<titleblockattributes> {}

type Props = ReadOnlyTitleBlockProps;

export default class ReadOnlyTitleBlock extends Component<props> {
  public render() {
    const { block } = this.props;
    return (
      <titleblockwrapper id="{block.getKey()}">
        <typography>
          <h1 0="" className="page__title" style="{{" marginBottom:="" }}="">
            {block.getAttribute('title') || ''}
          </h1>
          <p 0="" className="page__published-at" style="{{" marginTop:="" }}="">
            {block.getAttribute('timestamp') || ''}
          </p>
        </typography>
      </titleblockwrapper>
    );
  }
}
</props></titleblockattributes>