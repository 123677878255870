import { TextBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    html: { type: 'identity' },
    ops: { type: 'identity' },
  },
  icon: <textblock></textblock>,
  nombre: 'RICHTEXT',
  title: 'Texto enriquecido',
  v: 0,
};
