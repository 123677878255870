import {
  Action,
  DeepPartial,
  Middleware,
  Reducer,
  Store,
  applyMiddleware,
  createStore as createReduxStore,
} from 'redux';
import { EpicMiddleware, createEpicMiddleware } from 'redux-observable';
import { EpicDependencies } from '../epics';
import { History } from 'history';
import { authMiddleware } from '../middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

/**
 * Create store options.
 */
export interface CreateStoreOptions<s, A="" extends="" Action=""> {
  /**
   * Epic dependencies object for Redux Observable.
   */
  epicDependencies: EpicDependencies;

  /**
   * History object for Connected React Router.
   */
  history: History;

  middleware?: Middleware[];

  /**
   * The name of the store. This is used by Redux DevTools to distinguish
   * between multiple stores on the same page.
   */
  name: string;

  /**
   * Preloaded state, if any.
   */
  preloadedState?: DeepPartial<s>;

  /**
   * Root redux reducer function.
   */
  rootReducer: Reductor<s, A="">;
}

/**
 * Crear función de ayuda de tienda crear la configuración de una tienda redux del lado del cliente que
 * incluye middleware para Connected React Router, Redux Devtools, y Redux
 * Observable.
 * @param options Crear objeto de opciones de tienda.
 */
export const createStore = <s, A="" extends="" Action="">(
  opciones: CreateStoreOptions<s, A="">,
): [Almacenar<s, A="">EpicMiddleware<a>] => {
  const {
    epicDependencies,
    history,
    middleware = [],
    name,
    preloadedState = {},
    rootReducer,
  } = options;

  // Use Redux Devtools if present.
  const composeEnhancers = composeWithDevTools({
    name,
  });

  // Setup our redux-observable epic middleware.
  const epicMiddleware = createEpicMiddleware<a>({
    dependencies: epicDependencies,
  });

  const store = createReduxStore<s, A,="" {},="" {}="">(
    rootReducer,
    preloadedState,
    composeEnhancers(
      aplicarMiddleware(
        epicMiddleware,
        routerMiddleware(historia),
        authMiddleware(),
        ...middleware,
      ),
      // applyMiddleware(synchronizeViewportUrlMiddleware()),
    ),
  );

  return [store, epicMiddleware];
};
</s,></a></a></s,></s,></s,></s,></s></s,>