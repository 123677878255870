import { EventListener, getClientCoordinates } from '@robotsnacks/ui';
import { partial } from 'lodash';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';
import InsertLine from '../InsertLine';
import Placeholder from '../Placeholder';

const MIN_VISIBLE_DISTANCE = 50;

export interface StackBlockProps
  extends BlockComponentProps,
    Pick<blockpickerprops, 'blocks'=""> {}

type Props = StackBlockProps;

type State = {
  clientY?: number;
  activeLineIndex?: number;
};

const defaultProps = Object.freeze({
  blocks: [],
});

const initialState: State = Object.freeze({});

const isHovered = (state: State) => typeof state.clientY === 'number';

const lineIn = (hover: boolean, distances: number[], index: number) => {
  const distance = distances[index];
  return distance < MIN_VISIBLE_DISTANCE && hover;
};

const calculateNearestLineIndex = (distances: number[]) => {
  const min = Math.min(...distances);
  if (min === Infinity) return -1;
  return distances.indexOf(min);
};

export default class Stack extends Component<props, State=""> {
  state = initialState;

  private _lines: (HTMLElement | null)[] = [];

  public render() {
    // TODO: come up with an intelligent way to track block keys... right now
    // the last insert line will always blow up.
    // const hover = isHovered(this.state);
    return (
      <eventlistener target="window" onMouseMove="{this._handleMouseMove}">
        <div>{this._renderStackChildren()}</div>
      </eventlistener>
    );
  }

  private _handleMouseMove = (e: MouseEvent) => {
    const { clientY } = getClientCoordinates(e)!;
    this.setState({ clientY });
  };

  private _handleMouseLeave = () => {
    this.setState({ clientY: undefined });
  };

  private _renderStackChildren() {
    const children = this.props.block.getChildren();
    if (children.size > 0) {
      return this._renderChildBlocks();
    } else {
      return this._renderEmptyPlaceholder();
    }
  }

  private _renderEmptyPlaceholder() {
    return (
      <placeholder blocks="{this.props.blocks}" onSelect="{partial(this._handleSelect," 0)}=""></placeholder>
    );
  }

  private _renderChildBlocks() {
    const { block, blocks, renderBlock } = this.props;
    const { clientY } = this.state;
    const distances = this._calculateLineDistances(clientY);
    const nearestIndex = calculateNearestLineIndex(distances);
    const hover = isHovered(this.state);
    return block.getChildren().map((child, i, childBlocks) => {
      return (
        <react.fragment key="{child.getKey()}">
          <insertline blocks="{blocks}" domRef="{partial(this._setLineRef," i)}="" first="{i" =="=" 0}="" in="{lineIn(hover," distances,="" key="{`${child.getKey()}-div`}" nearest="{i" nearestIndex}="" onSelect="{partial(this._handleSelect,"></insertline>
          {renderBlock(child)}
          {i === childBlocks.size - 1 && (
            <insertline 1="==" blocks="{blocks}" domRef="{partial(this._setLineRef," i="" +="" 1)}="" in="{lineIn(hover," distances,="" key="{`${child.getKey()}-div-last`}" last="" nearest="{i" nearestIndex}="" onSelect="{partial(this._handleSelect,"></insertline>
          )}
        </react.fragment>
      );
    });
  }

  private _handleSelect = (index: number, type: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().insert([block.getKey(), index], type));
  };

  /**
   * Calculates the distance (in y-direction pixels) to each insert line from
   * the passed `clientY` coordinate. Returns an ordered list of these distances.
   */
  private _calculateLineDistances = (clientY?: number): number[] => {
    return this._lines.map(el => {
      if (!el || typeof clientY !== 'number') return Infinity;
      const { top } = el.getBoundingClientRect();
      return Math.abs(clientY - top);
    });
  };

  private _setLineRef = (i: number, el: HTMLElement | null) => {
    this._lines[i] = el;
  };
}
</props,></blockpickerprops,>