import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';
import SnipcartOptionsFlyout from './SnipcartOptionsFlyout';

type Props = {
  onDeleteClick?: () => void;
  attributes?: SnipcartBlockAttributes;
  onChange?: (attr: SnipcartBlockAttributes) => void;
} & Pick<blocktoolbarprops, 'block'="" |="" 'parentItems'="">;

const TITLE = 'Snipcart';

const SnipcartBlockToolbar: SFC<props> = ({
  onDeleteClick,
  onChange,
  atributos,
  ...resto
}) => (
  <blocktoolbar title="{TITLE}" {...rest}="">
    <snipcartoptionsflyout onChange="{onChange}" attributes="{attributes}"></snipcartoptionsflyout>
    <toolbarseparator></toolbarseparator>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITLE}"></deleteblockflyout>
  </blocktoolbar>
);

exportar por defecto SnipcartBlockToolbar;
</props></blocktoolbarprops,>