import { Typography, WithStyles, withStyles } from '@robotsnacks/ui';
import { DateTime, Duration } from 'luxon';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { EventBlockAttributes } from './EventBlockAttributes';
import EventBlockWrapper from './EventBlockWrapper';
import style from './style';

export interface ReadOnlyEventBlockProps
  extends BlockComponentProps<eventblockattributes> {}

type Atrezzo = ConEstilos<readonlyeventblockprops, typeof="" style="">;

class ReadOnlyEventBlock extends Componente<props, {}=""> {
  public render() {
    const { block } = this.props;
    return (
      <eventblockwrapper id="{block.getKey()}">
        {this.renderEvent()}
      </eventblockwrapper>
    );
  }

  private renderEvent() {
    const { block, classes } = this.props;
    const d = block.getAttribute('duration');
    const title = block.getAttribute('title');
    const start = DateTime.fromISO(block.getAttribute('start'));
    const duration = d ? Duration.fromISO(d) : null;
    return (
      <typography className="{classes.root}">
        <div className="{classes.date}">
          <span className="{classes.month}">{start.monthShort}</span>
          <span className="{classes.day}">{inicio.día}</span>
          <span className="{classes.year}">{inicio.año}</span>
        </div>
        <div className="{classes.text}">
          <h2 className="{classes.title}">{título}</h2>
          <p className="{classes.fullDate}">{start.toFormat('DDDD')}</p>
          <p className="{classes.time}">
            {start.toLocaleString(DateTime.TIME_SIMPLE)}
            {duración && (
              <>
                -{' '}
                {start.plus(duration).toLocaleString(DateTime.TIME_SIMPLE)}
               <span className="{classes.timezone}">
                  {start.toFormat('ZZZZ')}
               </span>
              </>
            )}
          </p>
        </div>
      </typography>
    );
  }
}

export default withStyles(style)(ReadOnlyEventBlock);
</props,></readonlyeventblockprops,></eventblockattributes>