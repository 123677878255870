import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import CardOptionsFlyout from './CardOptionsFlyout';
import PageListFlyout from './PageListFlyout';

type TmpPage = {
  description?: string;
  id: string;
  imageId?: string;
  title: string;
  to: string;
};

interface Props extends Pick<blocktoolbarprops, 'block'="" |="" 'parentItems'=""> {
  onDeleteClick?: () => void;
  onLinkSelect?: (page: TmpPage | string) => void;
  onShowSponsorChange?: (showSponsor: boolean) => void;
  showSponsor?: boolean;
}

const TITLE = 'Card';

const CardToolbar: SFC<props> = ({
  onDeleteClick,
  onLinkSelect,
  showSponsor,
  onShowSponsorChange,
  ...rest
}) => (
  <blocktoolbar title="{TITLE}" {...rest}="">
    <pagelistflyout href="{(rest" as="" any).block.getAttribute('href')}="" onSelect="{onLinkSelect}" onSaveClick="{(h:" string)=""> onLinkSelect!(h)}
    />
    <cardoptionsflyout showSponsor="{showSponsor}" onShowSponsorChange="{onShowSponsorChange}"></cardoptionsflyout>
    <toolbarseparator></toolbarseparator>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITLE}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator>
    <toolbarbutton>
      <icon>acción:configuración</icon>
    </toolbarbutton> */}
  </pagelistflyout></blocktoolbar>
);

exportar por defecto CardToolbar;
</props></blocktoolbarprops,>