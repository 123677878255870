// import { ToolbarButton, ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Rich Text';

const RichTextBlockToolbar: SFC<props> = ({ onDeleteClick, ...rest }) => (
  <blocktoolbar title="{TITLE}" {...rest}="">
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITLE}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator> */}
    {/* <toolbarbutton>
      <icon>acción:configuración</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

RichTextBlockToolbar.displayName = 'RichTextBlockToolbar';

exportar por defecto RichTextBlockToolbar;
</props>