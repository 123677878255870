import { DateTime, Duration } from 'luxon';
import React, { Component, createRef } from 'react';
import CardBlock, { CardBlockMediaType, CardBlockProps } from '../CardBlock';
import EventCardBlockToolbar from './EventCardBlockToolbar';
import EventDateTime from './EventDateTime';

export interface EventCardBlockProps extends CardBlockProps {
  eventList?: Array<{
    description?: string;
    id: string;
    imageId?: string;
    title: string;
    start: DateTime;
    duration: Duration;
  }>;
}

export default class EventCardBlock extends Component<
  EventCardBlockProps,
  any
> {
  state = {
    editorKey: 0,
  };

  private cardRef = createRef<any>();

  public render() {
    const { eventList, ...rest } = this.props;
    const block: any = this.props.block;
    return (
      <cardblock className="cs-card--event" ref="{this.cardRef}" {...rest}="" editorKey="{this.state.editorKey}" toolbar="{this._getToolbar()}">
        {block.getAttribute('start') && (
          <div 0="" style="{{" alignItems:="" 'center',="" borderRight:="" '1px="" solid="" rgba(0,="" 0,="" 0.15)',="" display:="" 'flex',="" flexDirection:="" 'column',="" justifyContent:="" padding:="" '0="" 0.5rem="" 0.35rem',="" }}="">
            <eventdatetime block="{this.props.block}"></eventdatetime>
          </div>
        )}
      </cardblock>
    );
  }

  private _getToolbar() {
    const { block, eventList, parentToolbarItems, toolbar } = this.props;
    const sponsor = block.getAttribute('sponsor');
    const showSponsor = sponsor ? sponsor.show : false;
    if (toolbar) {
      return toolbar;
    }
    return (
      <eventcardblocktoolbar block="{block}" eventList="{eventList}" onEventSelect="{this._handleEventSelect}" onDeleteClick="{this._handleDeleteClick}" parentItems="{parentToolbarItems}" showSponsor="{showSponsor}" onShowSponsorChange="{this._handleShowSponsorChange}"></eventcardblocktoolbar>
    );
  }

  private _handleShowSponsorChange = (show: boolean) => {
    const { block, getValue, onChange } = this.props;
    const sponsor = {
      ...block.getAttribute('sponsor'),
      show,
    };
    onChange(getValue().replace(block.setAttribute('sponsor', sponsor)));
  };

  private _handleEventSelect = (event: any) => {
    let editorKey = this.state.editorKey || 0;
    const { block, getValue, onChange } = this.props;
    let updatedBlock = block;

    updatedBlock = (block as any)
      .setAttribute('href', undefined)
      .setAttribute('to', event.page ? event.page.path : undefined)
      .setAttribute('start', event.start.toISO());

    if (
      event.description &&
      (block.getAttribute('html') || '').trim().length === 0
    ) {
      updatedBlock = updatedBlock.setAttribute(
        'html',
        `
          <h1>${event.title}</h1>
          <p>${event.description}</p>
          `.trim(),
      );
      editorKey += 2;
    }

    // Naively check if we have any images uploaded currently.
    if (event.imageId) {
      updatedBlock = this.cardRef.current.setImage(event.imageId, updatedBlock);
    }

    onChange(getValue().replace(updatedBlock));
    this.setState({ editorKey });
  };

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    onDelete(block);
  };
}
</any>