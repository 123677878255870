/* tslint:disable ordered-imports object-literal-sort-keys */

import { get, reduce } from 'lodash';
import React, { Component } from 'react';
import {
  Grid,
  GridEngine,
  GridProps,
  Scroller,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';
import { List } from 'immutable';
import Block from '../Block';
import { ReadOnlyEventCardBlock } from '../EventCardBlock';
import { TreeNode } from '../tree';
import normalizeItems from './normalize-items';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('background'),
    padding: 6,
    position: 'relative',
  },
}));

export interface FeedGridProps extends GridProps {
  flow?: string;
  feed: any;
  block: any;
  breakpointNames: any;
  breakpoints: any;
  currentBreakpoint: any;
  defaults: any;
  getBreakpointMedia: (breakpoint: string) => string | null;
  imageClient?: any;
}

type Props = WithStyles<feedgridprops, typeof="" styles="">;

class FeedGrid extends Componente<props> {
  private gridId = this.props.block.key.slice(0, 10);

  public shouldComponentUpdate(prevProps: any) {
    return false;
    // return (
    //   prevProps.feed !== this.props.feed || prevProps.flow !== this.props.flow
    // );
  }

  public render() {
    const { classes, flow } = this.props;
    return (
      <scroller 1="" style="{{" margin:="" '-20px="" 0',="" padding:="" '20px="" flex:="" }}="" suppressScrollY="">
        <grid 1="" id="{this.props.block.key}" style="{{" flex:="" }}="" className="{classes.root}" views="{this._createViews()}">
          {this._createChildren()}
        </grid>
      </scroller>
    );
  }

  private _createChildren() {
    const {
      feed,
      breakpoints,
      breakpointNames,
      getBreakpointMedia,
      currentBreakpoint,
      imageClient,
    } = this.props as any;

    return normalizeItems(feed).map((item: any) => {
      let shortDescription: string = '';
      let longDescription: string = '';

      shortDescription = (item.description as string)
        .slice(0, 200)
        .trim()
        .replace(/https?:\/\/[^\s$.?#].[^\s]*/gm, (match, $1) => {
          const a = /https?:\/\/([^\s$.?#].[^\s\/]*)/.exec(match);
          const b = a && a.length > 1 ? a[1] : match.slice(0, 40);
          return b;
        })
        .replace(/\n+/g, '<br>');

      longDescription = (item.description as string)
        .replace(/https?:\/\/[^\s$.?#].[^\s]*/gm, (match, $1) => {
          const a = /https?:\/\/([^\s$.?#].[^\s\/]*)/.exec(match);
          const b = a && a.length > 1 ? a[1] : match.slice(0, 40);
          return `<a target="_blank" href="${match}">${b}</a>`;
        })
        .replace(/\n+/g, '<br>');

      const useOnlyShortDescription = item.description.length <= 200;

      if (useOnlyShortDescription) {
        shortDescription = longDescription;
      } else {
        shortDescription = shortDescription.trim() + '...';
      }

      const block: any = new Block({
        getManager: this.props.block.getManager,
        node: new TreeNode({
          attributes: {
            breakpoints: {
              large: {
                media: {
                  filename: 'thumbnail',
                  height: 200,
                  id: item.imageId,
                  orientation: 'VERTICAL',
                  type: 'IMAGE',
                  width: 300,
                },
              },
              medium: {
                media: {
                  filename: 'thumbnail',
                  height: 200,
                  id: item.imageId,
                  orientation: 'VERTICAL',
                  type: 'IMAGE',
                  width: 300,
                },
              },
              small: {
                media: {
                  filename: 'thumbnail',
                  height: 200,
                  id: item.imageId,
                  orientation: 'VERTICAL',
                  type: 'IMAGE',
                  width: 300,
                },
              },
            },
            start: item.start,
            to: item.to,
            html: `
              <h1>${item.title}</h1>
              <p>${shortDescription}</p>
            `,
            expandedHtml: useOnlyShortDescription
              undefined
              : `
              <h1>${item.title}</h1>
              <p>${longDescription}</p>
            `,
          },
          niños: List(),
          tipo: 'EVENT_CARD',
        }),
      });
      return (
        <div key="{`_${this.gridId}${item.id}`}" id="{`_${this.gridId}${item.id}`}" style="{{" display:="" 'flex'="" }}="">
          <readonlyeventcardblock {...({="" block,="" breakpointNames,="" breakpoints,="" getBreakpointMedia,="" }="" as="" any)}="" currentBreakpoint="{currentBreakpoint}" imageClient="{imageClient}" defaults="{{}" any}="" block="{new" Block({})=""></readonlyeventcardblock>
        </div>
      );
    });
  }

  private _createViews() {
    const {
      feed,
      flow = 'right',
      breakpoints,
      defaults,
      getBreakpointMedia,
    } = this.props;

    const views = reduce(
      breakpoints,
      (acc, _, breakpointName) => {
        const curr = {};
        const media = getBreakpointMedia(breakpointName) as string;

        let engine = new GridEngine({ flow } as any);
        const defaultsForBreakpoint = defaults[breakpointName];
        const size = defaultsForBreakpoint.size;
        const columns = defaultsForBreakpoint.columns;

        const feedItems = normalizeItems(feed);

        if (flow === 'right') {
          feedItems.reverse().forEach((feedItem: any) => {
            const item = {
              ...size,
              column: 0,
              id: `_${this.gridId}${feedItem.id}`,
            };
            engine = engine.insertItem(item);
          });
        } else {
          // HACK: Don't even try to understand what's going on here. Rewrite.
          let column = 0;
          let row = 0;
          feedItems.forEach((feedItem: any) => {
            const item = {
              ...size,
              column,
              id: `_${this.gridId}${feedItem.id}`,
              row,
              width: size.width + 1,
            };
            engine = engine.insertItem(item);
            column =
              column + 2 * (size.width + 1) > columns
                ? 0
                : column + size.width + 1;
            row += column === 0 ? size.height : 0;
          });
        }

        return {
          ...acc,
          [breakpointName]: {
            columnWidth: defaultsForBreakpoint.columnWidth,
            columns: defaultsForBreakpoint.columns,
            engine,
            media,
            rowHeight: defaultsForBreakpoint.rowHeight,
          },
        };
      },
      {} as any,
    );

    return views;
  }
}

export default withStyles(styles)(FeedGrid);
</props></feedgridprops,>