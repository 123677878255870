import { List, Record } from 'immutable';
import { last } from 'lodash';

import Delta, {
  DeleteOp,
  InsertOp,
  ReplaceOp,
  isDeleteOp,
  isInsertOp,
  isReplaceOp,
  toArrayPath,
} from '../delta';

type Tree = import('./tree').default;

const DEFAULT_RECORD = {
  attributes: {} as { [name: string]: any },
  changedAt: 0 as number | undefined,
  children: List<treenode>(),
  key: '',
  type: '',
};

const del = Symbol();
const deleteChild = Symbol();
const getSiblingByOffset = Symbol();
const insert = Symbol();
const insertChild = Symbol();
const pathToChildNode = Symbol();
const replace = Symbol();
const replaceChild = Symbol();

/**
 * Creates an error message for a "block not found" error.
 * @param key The key of the block that wasn't found.
 */
const blockNotFound = (key: string) => `Block not found: ${key}`;

export default class TreeNode extends Record(DEFAULT_RECORD, 'TreeNode') {
  /**
   * Executes a depth-first search on the passed block.
   * @param block The root block at-which to start the search.
   * @param fn Callback function to execute.
   */
  static [pathToChildNode](
    block: TreeNode,
    fn: (block: TreeNode) => boolean,
    path: Array<treenode> = [],
  ): Matriz<treenode> {
    if (fn(block)) return [block];
    for (let i = 0; i < block.children.size; i++) {
      const b = block.children.get(i);
      if (!b) return [];
      const r = TreeNode[pathToChildNode](b, fn);
      if (r.length > 0) return path.concat([block]).concat(r);
    }
    return [];
  }

  /**
   * True if this block has no children.
   */
  get leaf(): boolean {
    return this.children.size === 0;
  }

  /**
   * The index of this block within its parent's children.
   */
  getIndex(graph: Tree) {
    const parent = graph.findParent(this);
    if (!parent) return null;
    return parent.children.indexOf(this);
  }

  /**
   * Retrieves the previous block in the document.
   */
  prev(graph: Tree) {
    // Traverse to the previous siblings last child, or the previous sibling
    // if it doesn't have any children.
    const prevSibling = this.prevSibling(graph);
    if (prevSibling) {
      const prevSiblingLastChild = prevSibling.children.last();
      return prevSiblingLastChild || prevSibling;
    }

    // Otherwise, if this block has a parent, and it's not the root, return the
    const parent = graph.findParent(this);
    if (parent && parent.key) return parent;

    // Return null if there's not a previous sibling.
    return null;
  }

  /**
   * Retrieves the next block in the document.
   */
  next(graph: Tree) {
    // Traverse down the tree to this block's first child, if present.
    const firstChild = this.children.first();
    if (firstChild) return firstChild;

    // If no children, travel to this blocks next sibling.
    const nextSibling = this.nextSibling(graph);
    if (nextSibling) return nextSibling;

    // Otherwise, travel to the parents next sibling.
    const parent = graph.findParent(this);
    if (parent) return parent.nextSibling(graph);

    // Return null if there's not a next sibling.
    return null;
  }

  /**
   * Gets and returns this block's previous sibling.
   */
  prevSibling(graph: Tree) {
    return this[getSiblingByOffset](graph, -1);
  }

  /**
   * Gets and returns this block's next sibling.
   */
  nextSibling(graph: Tree) {
    return this[getSiblingByOffset](graph, 1);
  }

  /**
   * Applies a delta to this block.
   * @param delta The delta to apply.
   */
  apply(graph: Tree, delta: Delta): this {
    return this.withMutations(node => {
      delta.forEach(op => {
        if (isInsertOp(op)) {
          node[insert](op, graph);
        } else if (isDeleteOp(op)) {
          node[del](op, graph);
        } else if (isReplaceOp(op)) {
          node[replace](op, graph);
        }
      });
    });
  }

  /**
   * Finds and returns a child block.
   * @param key The key to search for.
   */
  find(key: string): TreeNode | null {
    const path = this.findPath(key);
    const node = last(path);
    if (node) return node;
    return null;
  }

  /**
   * Finds and returns the path to a child block.
   * @param key The key of the child block.
   */
  findPath(key: string): Array<treenode> {
    return TreeNode[pathToChildNode](this, b => b.key === key);
  }

  /**
   * Tests if a node with the given key is contained by this node.
   * @param key
   */
  contains(key: string): boolean {
    return this.findPath(key).length > 0;
  }

  /**
   * Replaces a child of this node.
   * @param child The child to be replaced.
   */
  [replaceChild](graph: Tree, child: TreeNode): this {
    const index = this.children.findIndex(b => b.key === child.key);
    return this.withMutations(block => {
      block.set('children', this.children.set(index, child));
      block.set('changedAt', graph.change);
    });
  }

  /**
   * Deletes a child from this node.
   * @param key The key of the child to delete.
   */
  [deleteChild](graph: Tree, key: string): this {
    const index = this.children.findIndex(b => b.key === key);
    return this.withMutations(block => {
      block.set('children', this.children.delete(index));
      block.set('changedAt', graph.change);
    });
  }

  /**
   * Inserts a new child into this node.
   * @param child The child to be inserted.
   * @param index The index at-which to insert the new child.
   */
  [insertChild](graph: Tree, child: TreeNode, index: number): this {
    return this.withMutations(block => {
      block.set('children', this.children.insert(index, child));
      block.set('changedAt', graph.change);
    });
  }

  /**
   * Executes an insertion operation, treating this block as the root.
   * @param op The insert operation to be executed.
   */
  [insert](op: InsertOp, graph: Tree): TreeNode {
    // Deconstruct the operation to be performed.
    const { attributes = {}, type, key } = op;

    // Deconstruct the insertion path.
    const [parentKey, index] = toArrayPath(op.insert);

    // Find the path to the insertion-point's parent. If the key on the
    // insertion path is empty, then we're inserting into this node.
    const path = this.findPath(parentKey || this.key);
    const node = last(path);

    // Ensure the parent exists in this tree.
    if (!node) throw new Error(blockNotFound(key));

    // Construct the new child to be inserted.
    const child = new TreeNode({
      attributes,
      key,
      type,
      changedAt: graph.change,
    });

    // Determine the actual index where the new block should be inserted.
    // In the special case of "-0", then we should insert it at the end.
    const insertIndex =
      index === '-0' ? node.children.size : parseInt(index, 10);

    // Insert the new child in the last matching node, which is it's parent.
    let target = node[insertChild](graph, child, insertIndex);

    // Traverse up the tree updating parents.
    for (let i = path.length - 2; i >= 0; i--) {
      target = path[i][replaceChild](graph, target);
    }

    // Return this updated block.
    return target;
  }

  /**
   * Executes an replacement operation, treating this block as the root.
   * @param op The replace operation to be executed.
   */
  [replace](op: ReplaceOp, graph: Tree): TreeNode {
    // Deconstruct the operation.
    const { attributes = {}, type, replace: key } = op;

    // Locate the path to the block being replace.
    const path = this.findPath(key);
    const node = this.find(key);

    // Ensure the block to be replaced exists in this tree.
    if (!node) throw new Error(blockNotFound(key));

    // Create the replacement child.
    const child = new TreeNode({
      attributes,
      key,
      changedAt: graph.change,
      children: node.children,
      type: type || node.type,
    });

    // Travel up the tree, replacing children references.
    let target: TreeNode = child;
    for (let i = path.length - 2; i >= 0; i--) {
      target = path[i][replaceChild](graph, target);
    }

    // Return this updated block.
    return target;
  }

  /**
   * Executes an deletion operation, treating this block as the root.
   * @param op The delete operation to be executed.
   */
  [del](op: DeleteOp, graph: Tree): TreeNode {
    // Locate the path to the node that should be deleted.
    const path = this.findPath(op.delete);

    // Ensure the block to be deleted is in the tree.
    if (path.length === 0) throw new Error(blockNotFound(op.delete));

    // Refuse to delete the root block.
    if (path.length === 1) throw new Error('Refusing to delete root block.');

    // Delete the child.
    let target = path[path.length - 2][deleteChild](graph, op.delete);

    // Travel the path up to the root and update children.
    for (let i = path.length - 3; i >= 0; i--) {
      target = path[i][replaceChild](graph, target);
    }

    // Return this updated block.
    return target;
  }

  /**
   * Gets a sibling by some offset.
   * @param offset The number offset.
   */
  [getSiblingByOffset](graph: Tree, offset: number): TreeNode | null {
    const parent = graph.findParent(this);
    if (!parent) return null;
    const index = this.getIndex(graph);
    if (index !== null && index + offset >= 0) {
      return parent.children.get(index + offset) || null;
    }
    return null;
  }
}
</treenode></treenode></treenode></treenode>