import {
  Image,
  RichTextEditor,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';
import React, { Component } from 'react';
import { CardSponsor } from './CardBlockAttributes';

const styles = createStyles<'root'>(() => ({
  root: {
    // flex: 1,
    padding: '0.5em',
    position: 'relative',
    '& *:first-child': {
      marginTop: 0,
    },
    '& p': {
      fontSize: '0.85rem',
      fontStyle: 'italic',
      marginTop: '0.2em',
      opacity: 0.8,
    },
  },
}));

class ReadOnlyCardBlockSponsor extends Component<any> {
  public render() {
    const { block, classes, imageClient } = this.props;
    const sponsor: CardSponsor = block.getAttribute('sponsor');
    return (
      <div className="{cx(classes.root)}" style="{{" borderTop:="" '1px="" solid="" rgba(0,="" 0,="" 0.1)',="" display:="" 'flex',="" marginTop:="" '0.5rem',="" padding:="" }}="">
        <div style="{{" width:="" '33%',="" marginRight:="" '1rem',="" }}="">
          {patrocinador.id && (
            <image client="{imageClient}" imageId="{sponsor.id}" filename="sponsor" fit="inside" style="{{" width:="" '100%'="" }}=""></image>
          )}
        </div>
        <div style="{{" alignItems:="" 'center',="" flex:="" 1,="" position:="" 'relative',="" display:="" 'flex',="" }}="">
          <richtexteditor 1="" value="{sponsor.html}" style="{{" flex:="" }}="" readOnly=""></richtexteditor>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReadOnlyCardBlockSponsor);
</any>