import {
  AppAction,
  AuthAction,
  AuthActionType,
  LoginErrorAction,
  LoginRequestAction,
  LoginSuccessAction,
  loginError,
  loginSuccess,
} from '../../actions';
import { Epic, ofType } from 'redux-observable';
import { LOGIN_MUTATION, LoginPayload, LoginVariables } from '../../mutations';
import { AuthState } from '../../state';
import { EpicDependencies } from '../epic-dependencies';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const loginEpic: Epic<
  AuthAction,
  LoginSuccessAction | LoginErrorAction,
  { auth: AuthState },
  EpicDependencies
> = (
  action$,
  state,
  { client },
): Observable<loginsuccessaction |="" LoginErrorAction=""> =>
  action$.pipe(
    ofType<appaction, LoginRequestAction="">(AuthActionType.LoginRequest),
    switchMap(
      async (
        acción: LoginRequestAction,
      ): Promise<loginsuccessaction |="" LoginErrorAction=""> => {
        const { data, errors } = await client.mutate<
          LoginPayload,
          LoginVariables
        >({
          errorPolicy: 'all',
          mutation: LOGIN_MUTATION,
          variables: {
            input: {
              apiKey: action.apiKey,
              email: action.email,
              password: action.password,
            },
          },
        });

        if (data) {
          return loginSuccess(data.login);
        } else if (errors) {
          alert(
            'The email or password you entered is incorrect. Please contact support if you continue to have difficulties logging in.',
          );
          return loginError(errors);
        } else {
          throw new Error('No data or errors returned!');
        }
      },
    ),
  );
</loginsuccessaction></appaction,></loginsuccessaction>