import { DeckBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <deckblock></deckblock>,
  nombre: 'DECK',
  title: 'Cubierta',
  v: 0,
};
