import React, { Component } from 'react';
import { ReadOnlyCardBlock, ReadOnlyCardBlockProps } from '../CardBlock';
import EventDateTime from './EventDateTime';

export default class ReadOnlyEventCardBlock extends Component<
  ReadOnlyCardBlockProps
> {
  public render() {
    const { ...rest } = this.props;
    const block: any = this.props.block;
    return (
      <readonlycardblock className="cs-card--event" {...rest}="">
        {block.getAttribute('start') && (
          <div 0="" style="{{" alignItems:="" 'center',="" borderRight:="" '1px="" solid="" rgba(0,="" 0,="" 0.15)',="" display:="" 'flex',="" flexDirection:="" 'column',="" justifyContent:="" padding:="" '0="" 0.5rem="" 0.35rem',="" }}="">
            <eventdatetime block="{this.props.block}"></eventdatetime>
          </div>
        )}
      </readonlycardblock>
    );
  }
}
