import React, { SFC } from 'react';

import {
  Container,
  ContainerProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

export type RichTextBlockWrapperProps = ContainerProps;

type Props = WithStyles<richtextblockwrapperprops, typeof="" styles="">;

const TitleBlockWrapper: SFC<props> = ({
  niños
  clases,
  className,
  ...resto
}) => (
  <container className="{cx(className," classes.root)}="" type="prose" {...rest}="">
    {niños}
  </container>
);

TitleBlockWrapper.displayName = 'RichTextBlockWrapper';

exportar por defecto withStyles(styles)(TitleBlockWrapper);
</props></richtextblockwrapperprops,>