import ImageClient from '@robotsnacks/image-client';
import { CardMedia } from '@robotsnacks/ui';
import React, { Component } from 'react';
import ReadOnlyCardBlockImage from './ReadOnlyCardBlockImage';

import {
  CardBlockBreakpointMediaAttributes,
  CardBlockMediaOrientation,
  CardBlockMediaType,
} from './CardBlockAttributes';

export interface ReadOnlyCardBlockMediaProps {
  imageClient?: ImageClient;
  media: CardBlockBreakpointMediaAttributes;
}

type Props = ReadOnlyCardBlockMediaProps;

type State = {};

export default class CardBlockMedia extends Component<props, State=""> {
  render() {
    devolver (
      <cardmedia style="{this._getStyle()}">{this._renderMedia()}</cardmedia>
    );
  }

  private _getStyle() {
    const { height, width, orientation } = this.props.media;
    if (orientation === CardBlockMediaOrientation.Vertical && height && width) {
      return {
        height: `0`,
        width: '100%',
        paddingBottom: `${Math.floor((height / width) * 100)}%`,
        overflow: 'hidden',
      };
    } else {
      return {};
    }
  }

  private _renderMedia() {
    const { media, imageClient } = this.props;
    const { type } = media;
    if (type === CardBlockMediaType.Image) {
      return <readonlycardblockimage client="{imageClient}" media="{media}"></readonlycardblockimage>;
    }
  }
}
</props,>