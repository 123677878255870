import { Typography } from '@robotsnacks/ui';
import { debounce } from 'lodash';
import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { TitleBlockAttributes } from './TitleBlockAttributes';
import TitleToolbar from './TitleBlockToolbar';
import TitleBlockWrapper from './TitleBlockWrapper';

const HTML_DEBOUNCE = 500;
const HTML_MAX_WAIT = 2000;

export interface TitleBlockProps
  extends BlockComponentProps<titleblockattributes> {
  onDelete?: (block: Block<titleblockattributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

type Props = TitleBlockProps;

interface State {
  html?: string;
}

const initialState: State = Object.freeze({});

export default class TitleBlock extends Component<props, State=""> {
  static displayName = 'TitleBlock';
  state = initialState;

  private timePicker: React.Ref<htmlelement> = React.createRef<htmlelement>();

  // TODO: We need to update the value when the text editor unmounts, but we
  // can't do this because if the block is deleted, then trying to update it
  // with throw an error.
  // componentWillUnmount() {
  //   this._triggerChange();
  // }

  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    return (
      <toolbarhover block="{block}">
        <titleblockwrapper id="{block.getKey()}">
          <toolbarwrapper>
            {parentToolbar}
            <titletoolbar block="{block}" onDeleteClick="{this._handleDeleteClick}" parentItems="{parentToolbarItems}"></titletoolbar>
          </toolbarwrapper>
          <typography>
            <input className="h1 page__title" onChange="{this.handleTitleChange}" placeholder="{'Página" Title'}="" style="{{" border:="" 0,="" display:="" 'block',="" outline:="" padding:="" marginBottom:="" width:="" '100%',="" }}="" value="{block.getAttribute('title')" ||="" ''}="">
            <time>
              <input className="p page__published-at" onChange="{this.handlePublishedAtChange}" placeholder="{'Publicado" Date'}="" style="{{" border:="" 0,="" display:="" 'block',="" marginTop:="" outline:="" padding:="" width:="" '100%',="" }}="" value="{block.getAttribute('timestamp')" ||="" ''}="">
            </time>
          </typography>
        </titleblockwrapper>
      </toolbarhover>
    );
  }

  private handleTitleChange = (e: React.FormEvent<htmlinputelement>) => {
    const { block, getValue, onChange } = this.props;
    onChange(
      getValue().replace(
        block.setAttribute('title', (e.target as HTMLInputElement).value),
      ),
    );
  };

  private handlePublishedAtChange = (e: React.FormEvent<htmlinputelement>) => {
    const { block, getValue, onChange } = this.props;
    onChange(
      getValue().replace(
        block.setAttribute('timestamp', (e.target as HTMLInputElement).value),
      ),
    );
  };

  // private _handleTextChange = debounce(
  //   (html: string) => {
  //     const { block, getValue, onChange } = this.props;
  //     onChange(getValue().replace(block.setAttribute('html', html)));
  //   },
  //   HTML_DEBOUNCE,
  //   { leading: false, trailing: true, maxWait: HTML_MAX_WAIT },
  // );

  // private _handleChange = (html: string) => {
  //   this.setState({ html }, this._debouncedTriggerChange);
  // };

  // private _triggerChange = () => {
  //   const { block, getValue, onChange } = this.props;
  //   const updatedBlock = block.setAttribute('html', this.state.html || '');
  //   onChange(getValue().replace(updatedBlock));
  // };

  // private _debouncedTriggerChange = debounce(
  //   this._triggerChange,
  //   HTML_DEBOUNCE,
  //   {
  //     leading: false,
  //     trailing: true,
  //     maxWait: HTML_MAX_WAIT,
  //   },
  // );

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}
</htmlinputelement></htmlinputelement></htmlelement></htmlelement></props,></titleblockattributes></titleblockattributes>