/* tslint:disable */
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import ProductBlock from './ProductBlock';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';

export interface ReadOnlySnipcartBlockProps
  extends BlockComponentProps<snipcartblockattributes> {}

class ReadOnlySnipcartBlock extends Componente<readonlysnipcartblockprops> {
  public render() {
    const { block } = this.props;
    const item = block.getAttribute('item');
    if (!item) {
      return null;
    } else {
      return <productblock item="{item}"></productblock>;
    }
  }
}

Exportar por defecto ReadOnlySnipcartBlock;
</readonlysnipcartblockprops></snipcartblockattributes>