import { ActionAdd } from '@robotsnacks/icons';
import { noop, partial } from 'lodash';
import React, { PureComponent, createRef } from 'react';
import BlockPicker, { BlockPickerProps } from '../BlockPicker';
import DecorationsContext from '../DecorationsContext';

import {
  Popup,
  WithStyles,
  createStyles,
  cx,
  random,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<
  | 'root'
  | 'button'
  | 'children'
  | 'first'
  | 'focused'
  | 'horizontal'
  | 'icon'
  | 'in'
  | 'last'
  | 'line'
  | 'nearest'
  | 'popup'
  | 'vertical'
>(theme => ({
  root: {
    height: 0,
    pointerEvents: 'none',
    position: 'relative',
    '&$in, &$focused': {
      pointerEvents: 'auto',
    },
    '& $children, & $button': {
      height: 22,
      width: 22,
    },
  },
  button: {
    alignItems: 'center',
    background: theme.color('background'),
    borderRadius: 11,
    boxShadow: [
      `0 1px 12px ${theme.color('grey', '100')}`,
      `0 2px 2px ${theme.color('grey', '100')}`,
    ].join(','),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 11,
    justifyContent: 'center',
    opacity: 0,
    position: 'absolute',
    textAlign: 'center',
    transform: 'scale(0.7)',
    transition: 'opacity 200ms, transform 140ms',
    verticalAlign: 'middle',
    '$in &, $focused &': {
      opacity: 1,
    },
    '&:hover, $children:focus &, $root$nearest &': {
      transform: 'scale(1)',
    },
  },
  children: {
    outline: 0,
    position: 'relative',
    zIndex: 3,
  },
  first: {},
  focused: {
    '& $children': {
      zIndex: 5,
    },
  },
  horizontal: {
    '& $children': {
      top: -11,
      left: 'calc(50% - 11px)',
    },
    '&$first $children': {
      top: 1,
    },
    '&$last $children': {
      top: 'calc(100% - 23px)',
    },
    '&$first $button': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      transformOrigin: 'top center',
    },
    '&$last $button': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: [
        `0 0 12px ${theme.color('grey', '100')}`,
        `0 -1px 2px ${theme.color('grey', '100')}`,
      ].join(','),
      transformOrigin: 'bottom center',
    },
  },
  icon: {},
  in: {
    '& $line': {
      opacity: 1,
      zIndex: 3,
    },
  },
  last: {},
  line: {
    backgroundColor: theme.color('grey', '100'),
    height: 1,
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    transition: 'opacity 100ms',
    width: '100%',
    zIndex: 0,
    '$in &, $focused &': {
      background: theme.color('primary', '200'),
    },
  },
  nearest: {
    '& $children': {
      zIndex: 4,
    },
  },
  popup: {},
  vertical: {
    '& $children': {
      left: -11,
      top: 'calc(50% - 11px)',
    },
    '&$first $children': {
      left: 1,
    },
    '&$last $children': {
      left: 'calc(100% - 22px)',
    },
    '&$first $button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      transformOrigin: 'left center',
    },
    '&$last $button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      transformOrigin: 'right center',
    },
    '& $line': {
      width: 1,
      height: '100%',
    },
  },
}));

export interface InsertLineProps extends BlockPickerProps {
  className?: string;
  decorationKey?: string;
  domRef?: (el: HTMLElement | null) => void;
  first?: boolean;
  in?: boolean;
  last?: boolean;
  nearest?: boolean;
  vertical?: boolean;
}

type Props = WithStyles<insertlineprops, typeof="" styles=""> & typeof defaultProps;

type State = {
  focused?: boolean;
};

const defaultProps = Object.freeze({
  onSelect: noop,
});

const initialState = Object.freeze({
  focused: false,
});

class InsertLine extends PureComponent<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  private _target = createRef<htmldivelement>();
  private _decorationKey = random();

  render() {
    const {
      blocks,
      classes,
      className,
      domRef,
      first,
      last,
      nearest,
      vertical,
    } = this.props;

    const { focused } = this.state;

    return (
      <decorationscontext.consumer>
        {({ blur, focus, shouldDecorate }) => (
          <div className="{cx(" classes.root,="" vertical="" &&="" classes.vertical,="" !vertical="" classes.horizontal,="" first="" classes.first,="" last="" classes.last,="" nearest="" classes.nearest,="" focused="" classes.focused,="" this.props.in="" shouldDecorate(this._decorationKey)="" classes.in,="" className,="" )}="" ref="{domRef}">
            <div className="{classes.line}"></div>
            <popup in="{this.state.focused}" popup="{" <BlockPicker="" blocks="{blocks}" onSelect="{this._handleSelect}"></popup>
              }
              style={{ zIndex: 100 }}
            >
              {({ popup }) => (
                <div onFocus="{partial(this._handleFocus," focus)}="" onBlur="{partial(this._handleBlur," blur)}="" className="{classes.children}" tabIndex="{0}" ref="{this._target}">
                  <div className="{classes.button}">
                    <actionadd></actionadd>
                  </div>
                  {popup}
                </div>
              )}
            
          </div>
        )}
      </decorationscontext.consumer>
    );
  }

  private _handleFocus = (focus: (decorationKey: string) => void) => {
    focus(this._decorationKey);
    this.setState({ focused: true });
  };

  private _handleBlur = (blur: (decorationKey: string) => void) => {
    blur(this._decorationKey);
    this.setState({ focused: false });
  };

  private _handleSelect = (name: string) => {
    const { current } = this._target;
    if (current) current.blur();
    this.props.onSelect(name);
  };
}

export default withStyles(styles)(InsertLine);
</htmldivelement></props,></insertlineprops,>