/* tslint:disable variable-name */

import { Button } from '@robotsnacks/ui';
import React, { Component } from 'react';

class FeedBuilderPopup extends Component<any, any=""> {
  public state: any = {
    afterType: 'today',
    cql: '',
    excludeTags: '',
    flow: 'down',
    withTags: '',
  };

  public render() {
    return (
      <div onClick="{e" ==""> {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ padding: '0.5rem', width: '240px' }}
      >
        <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
          <p>Disposición de los piensos</p>
          <select style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ flow: e.target.value })}
            value={this.state.flow}
          >
            <option value="down">Mostrar como cuadrícula</option>
            <option value="right">Mostrar como cubierta</option>
            <option value="list">Mostrar como lista</option>
          </select>
        </div>
        <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
          <p>Consulta</p>
          <input type="text" style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ cql: e.target.value })}
            value={this.state.cql}
          />
        </div>
        <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
          <p>Mostrar eventos Después</p>
          <select style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ afterType: e.target.value })}
            value={this.state.afterType}
          >
            <option value="today">Hoy</option>
            <option value="specificDate">Fecha específica</option>
          </select>
        </div>
        {this.state.afterType === 'specificDate' && (
          <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
            <input type="date" style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ after: e.target.value })}
            />
          </div>
        )}
        <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
          <p>Mostrar eventos Antes</p>
          <input type="date" style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ before: e.target.value })}
          />
        </div>
        <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
          <p>Mostrar eventos con etiquetas</p>
          <input type="text" style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ withTags: e.target.value })}
          />
        </div>
        <div style="{{" margin:="" '0.5rem="" 0',="" position:="" 'relative'="" }}="">
          <p>Excluir eventos con etiquetas</p>
          <input type="text" style="{{" boxSizing:="" 'border-box',="" width:="" '100%'="" }}="" onChange="{e" ==""> this.setState({ excludeTags: e.target.value })}
          />
        </div>
        <div style="{{" marginBottom:="" '0.5rem'="" }}="">
          <button onClick="{this._handleSaveClick}">Guardar</button>
        </div>
      </div>
    );
  }

  private _handleSaveClick = () => {
    this.props.onSaveClick({
      after: this.state.afterType === 'today' ? 'today' : this.state.after,
      before: this.state.before,
      cql: this.state.cql,
      excludeTags: this.state.excludeTags || '',
      flow: this.state.flow,
      withTags: this.state.withTags || '',
    });
  };
}

export default FeedBuilderPopup;
</any,>