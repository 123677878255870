import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import ImageOptionsFlyout from './ImageOptionsFlyout';

type Props = {
  altText?: string;
  format?: string;
  link?: string;
  onDeleteClick?: () => void;
  onFormatChange?: (format: string) => void;
  onLinkChange?: (link: string) => void;
  onAltTextChange?: (altText: string) => void;
  onPinterestDescriptionChange?: (description: string) => void;
  pinterestDescription?: string;
} & Pick<blocktoolbarprops, 'block'="" |="" 'parentItems'="">;

const TITLE = 'Imagen';

const ImageBlockToolbar: SFC<props> = ({
  altText,
  formato,
  link,
  onAltTextChange,
  onDeleteClick,
  onFormatChange,
  onLinkChange,
  onPinterestDescriptionChange,
  pinterestDescription,
  ...rest
}) => (
  <blocktoolbar title="{TITLE}" {...rest}="">
    <imageoptionsflyout onAltTextChange="{onAltTextChange}" onLinkChange="{onLinkChange}" altText="{altText}" link="{link}" onFormatChange="{onFormatChange}" format="{format}" onPinterestDescriptionChange="{onPinterestDescriptionChange}" pinterestDescription="{pinterestDescription}"></imageoptionsflyout>
    <toolbarseparator></toolbarseparator>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITLE}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator> */}
    {/* <toolbarbutton>
      <icon>acción:configuración</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

exportar por defecto ImageBlockToolbar;
</props></blocktoolbarprops,>