import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import Placeholder from '../Placeholder';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { CodeBlockAttributes } from './CodeBlockAttributes';
import CodeBlockToolbar from './CodeBlockToolbar';
import CodeWrapper from './CodeWrapper';

type Props = {
  onDelete?: (block: Block<codeblockattributes>) => void;
  parentToolbar? ReactNode;
  parentToolbarItems? ReactNode;
  openCodeEditor?: (
    fuente: string,
    editorInterface: CodeBlockEditorInterface,
  ) => any;
} & BlockComponentProps<codeblockattributes>;

type State = {
  editorOpen?: boolean;
  preview?: string;
};

export interface CodeBlockEditorInterface {
  discard: () => void;
  preview: (compiled: string) => void;
  save: (source: string, compiled: string) => void;
  onEditorClose: () => void;
}

const initialState = Object.freeze({
  editorOpen: false,
  preview: '',
});

const handleDeleteClick = Symbol();

export default class WritableCode extends Component<props, State=""> {
  state = initialState;

  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    const hasCode = this._hasCode();
    return (
      <toolbarhover block="{block}">
        <div id="{block.getKey()}" style="{{" position:="" 'relative',="" flex:="" 1,="" display:="" 'flex'="" }}="">
          <toolbarwrapper>
            {parentToolbar}
            <codeblocktoolbar block="{block}" onDeleteClick="{this[handleDeleteClick]}" onEditClick="{this._handleOpenEditor}" parentItems="{parentToolbarItems}"></codeblocktoolbar>
          </toolbarwrapper>
          {hasCode && (
            <codewrapper block="{block}" preview="{this.state.preview}"></codewrapper>
          )}
          {!hasCode && (
            <placeholder 1="" style="{{" flex:="" }}="" disableBlockPicker="" onClick="{this._handleOpenEditor}" title="Bloque de códigos" message="Click to edit."></placeholder>
          )}
        </div>
      </toolbarhover>
    );
  }

  private _hasCode() {
    const html = this.props.block.getAttribute('html');
    const { preview } = this.state;
    return (preview || html || '').trim().length > 0;
  }

  [handleDeleteClick] = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };

  private _handleOpenEditor = () => {
    if (this.state.editorOpen) return;
    const { openCodeEditor } = this.props;
    if (openCodeEditor) {
      const source = this.props.block.getAttribute('source') || '';
      const proxy = openCodeEditor('', {
        discard: this._handleDiscard,
        onEditorClose: this._handleEditorClose,
        preview: this._handlePreview,
        save: this._handleSave as any,
      });

      proxy._messenger.on('READY', () => {
        proxy._messenger.send('UPDATE_VALUE', source);
      });

      this.setState({ editorOpen: true });
    }
  };

  private _handlePreview = (compiled: string) => {
    this.setState({ preview: compiled });
  };

  private _handleSave = (source: string, { html }: { html: string }) => {
    const { block, getValue, onChange } = this.props;
    onChange(
      getValue().replace(
        block.setAttribute('html', html).setAttribute('source', source),
      ),
    );
    this.setState({ preview: '' });
  };

  private _handleDiscard = () => {
    this.setState({ preview: '' });
  };

  private _handleEditorClose = () => {
    this.setState({ editorOpen: false, preview: '' });
  };
}
</props,></codeblockattributes></codeblockattributes>