import { Scroller } from '@robotsnacks/ui';
import React, { Component } from 'react';

import {
  ReadOnlyBaseGridBlock,
  ReadOnlyBaseGridBlockProps,
} from '../BaseGridBlock';

export type ReadOnlyDeckBlockProps = ReadOnlyBaseGridBlockProps;

type Props = ReadOnlyDeckBlockProps;

export default class ReadOnlyDeckBlock extends Component<props> {
  render() {
    devolver (
      <scroller className="{'cs-deck'}" suppressScrollY="">
        <readonlybasegridblock {...this.props}=""></readonlybasegridblock>
      </scroller>
    );
  }
}
</props>